<template>
  <div>
    <v-tooltip
      v-if="productLine.status === 1"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingProductLine"
          color="red"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="onDeactiveWarehouse"
        >
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para inhabilitar el almacen</span>
    </v-tooltip>

    <v-tooltip
      v-else
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingProductLine"
          color="green"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="onActiveWarehouse"
        >
          <v-icon>
            mdi-check
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para habilitar el almacen</span>
    </v-tooltip>

    <v-btn
      color="yellow"
      class="transparent-button-icon"
      icon
      @click="openedEditDialog = true"
    >
      <v-icon>
        mdi-pencil
      </v-icon>
    </v-btn>
    <edit-product-line-dialog
      v-model="openedEditDialog"
      :product-line="productLine"
    />
  </div>
</template>

<script>
  import EditProductLineDialog from './EditProductLineDialog.vue'
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'ProductLineActions',
    components: {
      EditProductLineDialog,
    },
    props: {
      productLine: {
        type: Object,
        default () {
          return {}
        },
      },
    },
    data () {
      return {
        openedEditDialog: false,
      }
    },
    computed: {
      ...mapState([
        'fetchingProductLine',
        'user',
        'actionsParentBusiness',
      ]),
    },
    methods: {
      ...mapActions([
        'deleteProductLine',
        'activeProductLine',
        'fetchProductLine',
      ]),
      async onDeactiveWarehouse () {
        await this.deleteProductLine({ id: this.productLine.id })
        await this.fetchProductLine({ id: this.user.businessId || this.actionsParentBusiness })
      },
      async onActiveWarehouse () {
        await this.activeProductLine({ id: this.productLine.id })
        await this.fetchProductLine({ id: this.user.businessId || this.actionsParentBusiness })
      },
    },
  }
</script>

<style scoped>
  .transparent-button-icon {
    background-color: transparent !important;
  }
</style>
