<template>
  <v-dialog
    v-model="openedDialog"
    max-width="500"
  >
    <v-card>
      <v-card-title>
        Crear una linea de productos
      </v-card-title>
      <add-product-line-form @close="openedDialog = false" />
    </v-card>
  </v-dialog>
</template>

<script>
  import AddProductLineForm from './AddProductLineForm.vue'

  export default {
    name: 'AddWarehouses',
    components: { AddProductLineForm },
    props: {
      value: {
        type: Boolean,
        default () {
          return false
        },
      },
    },
    computed: {
      openedDialog: {
        get () {
          return this.value
        },
        set (v) {
          this.$emit('input', v)
        },
      },
    },
  }
</script>
