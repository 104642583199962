<template>
  <product-line-table />
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  // import WarehousesTable from '../components/admin-warehouses/WarehousesTable'
  import ProductLineTable from '../components/product-line/ProductLineTable.vue'
  export default {
    name: 'AdminWarehouses',
    components: {
      ProductLineTable,
    },
    computed: {
      ...mapState(['user', 'actionsParentBusiness']),
    },

    async created () {
      await this.fetchProductLine({ id: this.user.businessId || this.actionsParentBusiness })
    },

    methods: {
      ...mapActions([
        'fetchProductLine',
      ]),
    },
  }
</script>
