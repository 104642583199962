<template>
  <v-form
    v-model="isValidForm"
    @submit="handleSave"
  >
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="data.name"
            type="text"
            :rules="requiredFieldRules('Nombre es requerido')"
            outlined
            label="Nombre"
            required
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        color="red"
        type="button"
        @click="$emit('close')"
      >
        Cerrar
      </v-btn>
      <v-btn
        text
        color="green"
        type="submit"
      >
        Guardar
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'EditWarehousesForm',
    props: {
      productLine: {
        type: Object,
        default () {
          return {}
        },
      },
    },
    data () {
      return {
        isValidForm: false,
        name: '',
        data: {
          name: '',
        },
      }
    },

    computed: {
      ...mapState(['user', 'actionsParentBusiness']),
    },

    watch: {
      data (v) {
        // // // // // // // // // // // // // // // // // // // // // // // // // console.log('DATA', v)
      },
    },

    mounted () {
      this.data.name = this.productLine.description
      this.data.id = this.productLine.id
      // // // // // // // // // // // // // // // // // // // // // // // // // // console.log('ware', this.warehouse)
      // this.data = this.warehouse
    },

    methods: {
      ...mapActions([
        'updateProductLine',
        'fetchProductLine',
      ]),
      async handleSave (e) {
        e.preventDefault()
        if (this.isValidForm) {
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log('datita', this.data)
          await this.updateProductLine({
            id: this.data.id,
            description: this.data.name,
            businessId: this.user.businessId || this.actionsParentBusiness,
          })
          this.$emit('close')
          await this.fetchProductLine({ id: this.user.businessId || this.actionsParentBusiness })
        }
      },
      requiredFieldRules (errorMessage) {
        return [
          v => !!v || errorMessage,
        ]
      },
    },
  }
</script>
