<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
  >
    <v-card>
      <v-card-title>Editar Linea de productos</v-card-title>
      <edit-product-line-form
        :product-line="productLine"
        @close="dialog = false"
      />
    </v-card>
  </v-dialog>
</template>

<script>
  import EditProductLineForm from './EditProductLineForm'
  export default {
    name: 'EditProductLineDialog',
    components: {
      EditProductLineForm,
    },
    props: {
      productLine: {
        type: Object,
        default () {
          return {}
        },
      },
      value: {
        type: Boolean,
        default () {
          return false
        },
      },
    },
    computed: {
      dialog: {
        get () {
          return this.value
        },
        set (v) {
          this.$emit('input', v)
        },
      },
    },
  }
</script>
