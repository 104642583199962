<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card
          icon="mdi-chart-timeline-variant"
          title="Linea de productos"
          class="px-5 py-3"
          color="orange"
        >
          <v-data-table
            :headers="headers"
            :items="productLine"
            :loading="fetchingProductLine"
            loading-text="Cargando... Por favor, espere"
            no-data-text="No hay datos disponibles"
            :footer-props="{
              'items-per-page-text': 'Filas por página',
              pageText: '{0}-{1} de {2}'
            }"
          >
            <template v-slot:top>
              <product-line-table-top />
            </template>
            <template v-slot:[`item.actions`]="{ item: productLine }">
              <product-line-actions
                :product-line="productLine"
              />
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import ProductLineTableTop from './ProductLineTableTop'
  import ProductLineActions from './ProductLineActions.vue'

  export default {
    name: 'ProductLineTable',
    components: {
      ProductLineTableTop,
      ProductLineActions,
    },
    data () {
      return {
        headers: [
          {
            text: 'Codigo',
            value: 'description',
          },
          {
            text: 'Acciones',
            value: 'actions',
            align: 'center',
            sortable: false,
          },
        ],
      }
    },
    computed: {
      ...mapState([
        'productLine',
        'fetchingProductLine',
      ]),
    },
  }
</script>
